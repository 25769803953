import { environment } from "../../../environments/environment";

export class Report {
  private baseUrl: string = environment.apiURL;
  private serviceUrl: string = this.baseUrl + "/report";

  reportTypes = () => `${this.serviceUrl}/Report/reportTypes`;
  occurrenceType = () => `${this.serviceUrl}/Report/occurenceTypes`;
  automatedReports = (
    pageNumber: number,
    pageSize: number,
    customerId: number,
    searchString: string
  ) => {
    let url = `${this.serviceUrl}/AutomatedReport/automatedReports/${pageNumber}/${pageSize}/${customerId}`;
    if (searchString) {
      url += `?searchString=${searchString}`;
    }
    return url;
  };
  reportsList = (
    pageNumber: number,
    pageSize: number,
    searchString: string
  ) => {
    let url = `${this.serviceUrl}/DocumentReport/reports/${pageNumber}/${pageSize}`;
    if (searchString) {
      url += `?searchString=${searchString}`;
    }
    return url;
  };
  generateReport = () => `${this.serviceUrl}/Report/generateReport`;
  reportByReportId = (reportId: number) => `${this.serviceUrl}/Report/report/${reportId}`;
  closedPtwReports = () => `${this.serviceUrl}/AutomatedReport/Download/ClosedPtwReports`;
}
