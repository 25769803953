import { environment } from "../../../environments/environment";

export class Contentmanagement {
  private baseUrl: string = environment.apiURL;
  private serviceUrl: string = this.baseUrl + "/contentmanagement";

  componentTypes = (filterOption: string) =>
    `${this.serviceUrl}/Document/componentTypes?filter=${filterOption}`;
  classDescriptions = (compType: string, filterOption: string) =>
    `${this.serviceUrl}/Document/componentClasses?type=${compType}&filter=${filterOption}`;
  designDocuments = () => `${this.serviceUrl}/Document/desingDocs`;
  subAssetRelatedDocuments = (assetId: number, feeder: string) =>  `${this.serviceUrl}/Logbook/subAssetRelatedDocuments/${assetId}/${feeder}`;

  downloadDocument = (docUrl: string, repoId: string) => {
    let url = `${this.serviceUrl}Logbook/single/download/?link=${docUrl}`;
    if (repoId) url += `&repoId=${repoId}`;
    return url;
  }

}
